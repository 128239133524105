import React from 'react';

const HeroSection: React.FC = () => {
return (
<div className="bg-gray-50">
    <div className="relative isolate overflow-hidden pt-14">
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div
                className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                <h1
                    className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                    Let our AI take over the ugliest 4 hours of your work week
                </h1>
                <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                    <p className="text-lg leading-8 text-gray-600">
                        Automate your workflows and connect your tools effortlessly, saving you time and letting you
                        focus on what truly matters.
                    </p>
                    <div className="mt-10 flex items-center gap-x-6">
                        <a href="#"
                            className="rounded-md bg-sky-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800">
                            Start Your Free Trial
                        </a>
                        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
                <img alt="AI-powered automation illustration"
                    src="/img/undraw_visionary_technology.svg"
                    className="w-2/3 rounded-2xl object-cover -mt-16 mx-auto lg:max-w-none xl:row-span-2 xl:row-end-2 " />
            </div>
        </div>
    </div>
</div>
);
};

export default HeroSection;
