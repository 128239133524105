import { motion } from 'framer-motion';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { GraphQLClient, gql } from 'graphql-request';
import { useEffect, useState } from 'react';

// Define TypeScript interfaces for the post data structure
interface Author {
  name: string;
  role: string;
  imageUrl: string;
}

interface Category {
  title: string;
  href: string;
}

interface Post {
  id: string;
  title: string;
  href: string;
  description: string;
  date: string;
  datetime: string;
  author: Author;
}


// Fetching the blog posts from Contentful using GraphQL
async function blogquery(): Promise<Post[]> {
  const endpoint = 'https://graphql.contentful.com/content/v1/spaces/b8exbjlcecvz';
  // const query = gql`
  //   query GetBlogPosts {
  //     aiPostCollection {
  //       items {
  //         title
  //         description
  //         urlName
  //         date
  //         author {
  //           name
  //           role
  //           imageUrl
  //         }
  //       }
  //     }
  //   }
  // `;
  const query = gql`
  query GetBlogPostByUrlName{
      aiPostCollection{
          items{
            title
            description
            urlName
            date
          }
        }
      }
  `

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      Authorization: 'Bearer Y9k68HidHsj7hfIfxud23KXx6sjRnOZX8NTlM92rgwc',
    },
  });

  const data = await graphQLClient.request(query);
  return data.aiPostCollection.items.map((item: any) => ({
    id: item.urlName,
    title: item.title,
    href: `/blog/post?id=${item.urlName}`,
    description: item.description,
    date: item.date || 'Unknown date',
    datetime: item.date ? new Date(item.date).toISOString() : '',
    category: { title: item.category || 'General', href: '#' },
    author: {
      name: item.author?.name || 'Unknown author',
      role: item.author?.role || 'Author',
      imageUrl: item.author?.imageUrl || 'https://via.placeholder.com/256',
    },
  }));
}

export default function AIBlog() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    blogquery()
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (isLoading) return <span>Loading...</span>;

  return (
    <>
      <Header />
      <div className="py-16 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AI Innovation Blog</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
            All about latest AI advancements and how they can be leveraged for your business
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                  {new Date(post.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })}
                  </time>
                  <a
                    href="#"
                    className="relative z-10 rounded-full bg-gray-100 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-200"
                  >
                    AI
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <img alt="" src={require('../../img/justin.jpg')} className="h-10 w-10 rounded-full bg-gray-50" />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a href="#">
                        <span className="absolute inset-0" />
                        Justin Kater
                      </a>
                    </p>
                    <p className="text-gray-600">Founder & CEO</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
