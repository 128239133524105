import React from 'react';

const LastSection: React.FC = () => {
return (
<div className="bg-gray-50">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Boost your productivity. <br />
                Start using higent.ai today.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                Save time, reduce errors, and streamline your business processes with higent.ai.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <a href="https://pagewhereuserentershisemail.ktr-solutions.com"
                    className="rounded-md bg-sky-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800">
                    Get started
                </a>
            </div>
        </div>
    </div>
</div>
);
};

export default LastSection;