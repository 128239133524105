import React from "react"
import Contact from "../routes/Contact";
import NotFound from "../routes/NotFound";
import About from "../routes/About";
import Imprint from "../routes/Imprint";
import AIBlog from "../routes/blog/AIBlog";
import AIBlogPost from "../routes/blog/AIBlogPost";
import LandingKTRSolutions from "../routes/LandingKTRSolutions";
import { AnimatePresence } from "framer-motion"

import {
    Route,
    Routes,
    useLocation
  } from "react-router-dom";

function AnimatedRoutes(){
    const location = useLocation();
    return(
        <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            {/* public  */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/blog" element={<AIBlog />} />
            <Route path="/blog/post" element={<AIBlogPost />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<LandingKTRSolutions />} />
        </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;