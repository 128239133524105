import React from 'react';
import { CodeBracketIcon, InboxIcon, TrashIcon, UsersIcon, CpuChipIcon } from '@heroicons/react/24/outline';
import { LiaConnectdevelop } from "react-icons/lia";

const features = [
    {
        name: 'AI-Driven Event Orchestration',
        description: 'Harness the power of AI to analyze and orchestrate complex workflows across your entire software stack, ensuring efficiency and precision.',
        icon: CpuChipIcon,
    },
    {
        name: 'No-Code Automation',
        description: 'Create sophisticated automations without writing a single line of code, making it accessible for everyone on your team to build and manage workflows.',
        icon: CodeBracketIcon,
    },
    {
        name: 'Seamless Integrations',
        description: 'Connect with your favorite tools effortlessly—Jira, Slack, GitHub, and more—to streamline your processes and ensure seamless communication between systems.',
        icon: LiaConnectdevelop,
    }
];


const FeatureSection: React.FC = () => {
return (
<div className="bg-gray-50 py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Stay on top of your processes
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
                higent.ai offers powerful features to automate and streamline your business processes, so you can focus
                on what truly matters.
            </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-sky-800">
                            <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                        </div>
                        {feature.name}
                    </dt>
                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                        <p className="flex-auto">{feature.description}</p>
                        <p className="mt-6">
                            <a href="#" className="text-sm font-semibold leading-6 text-sky-800">
                                Learn more <span aria-hidden="true">→</span>
                            </a>
                        </p>
                    </dd>
                </div>
                ))}
            </dl>
        </div>
    </div>
</div>
);
};

export default FeatureSection;