import React, { useEffect, useRef, useState } from 'react';
import { ArrowPathIcon, CommandLineIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

const features = [
    {
        name: 'Customizable Workflow Guidance',
        description:
        'higent.ai adapts to your unique business processes, guiding you step-by-step to create workflows that truly fit your operations—no more forced adaptation to rigid software flows.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Intuitive No-Code Automation',
        description:
        'Empower your team to build and manage complex workflows effortlessly with our intuitive no-code platform, tailored to your specific needs and processes.',
        icon: CommandLineIcon,
    },
    {
        name: 'Personalized Tool Integrations',
        description:
        'Seamlessly integrate the tools you already use Jira, Slack, GitHub, etc. into your workflows, ensuring that every process follows your business, not predefined software constraints.',
        icon: ArrowTrendingUpIcon,
    },
];

export default function GuideSection() {
    const leftSectionRef = useRef(null);
    const rightSectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState({ left: false, right: false });

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.7,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target === leftSectionRef.current) {
                        setIsVisible((prev) => ({ ...prev, left: true }));
                    } else if (entry.target === rightSectionRef.current) {
                        setIsVisible((prev) => ({ ...prev, right: true }));
                    }
                }
            });
        }, options);

        if (leftSectionRef.current) observer.observe(leftSectionRef.current);
        if (rightSectionRef.current) observer.observe(rightSectionRef.current);

        return () => {
            if (leftSectionRef.current) observer.unobserve(leftSectionRef.current);
            if (rightSectionRef.current) observer.unobserve(rightSectionRef.current);
        };
    }, []);

    return (
        <div className="overflow-hidden bg-gray-50 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <div
                        ref={leftSectionRef}
                        className={`lg:pr-8 lg:pt-4 transition-opacity duration-2000 ease-in-out ${isVisible.left ? 'opacity-100' : 'opacity-0'}`}
                    >
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-sky-800">AI-Powered Automation</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Streamline Your Workflow</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                higent.ai is designed to simplify your workflow by integrating all your favorite tools and
                                automating tasks with AI at the core. Focus on growing your business while we take care of the
                                rest.
                            </p>
                        </div>
                        <div className="mt-6">
                            <a
                                href="#"
                                className="rounded-md bg-sky-800 mt-12 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800"
                            >
                                Try now
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col items-center justify-center">
                            <h3 className="text-4xl font-semibold leading-7 text-gray-900 -mt-20">Meet higent.ai!</h3>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                        <img
                            alt="AI-powered automation illustration"
                            src="/img/higent-logo.png"
                            className="w-3/4 object-cover mt-28"
                        />
                        </div>
                    </div>
                    <div
                        ref={rightSectionRef}
                        className={`transition-opacity duration-2000 ease-in-out ${isVisible.right ? 'opacity-100' : 'opacity-0'}`}
                    >
                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="inline font-semibold text-gray-900">
                                        <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-sky-800" />
                                        {feature.name}
                                    </dt>{' '}
                                    <dd className="">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
}
