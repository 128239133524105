import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import Header from "../../components/Header";
import { GraphQLClient, gql } from "graphql-request";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Document,
  Inline,
  INLINES,
  MARKS,
} from "@contentful/rich-text-types";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";
import CallToAction from "../../components/CallToAction";

async function blogquery() {
  const endpoint =
    "https://graphql.contentful.com/content/v1/spaces/b8exbjlcecvz";

  const queryParams = new URLSearchParams(window.location.search);

  const postname = queryParams.get("id");
  const urlName = postname;

  const query = gql`
    query GetAIPostByUrlName($urlName: String!) {
      aiPostCollection(where: { urlName: $urlName }, limit: 1) {
        items {
          title
          date
          content {
            json
            links {
              entries {
                inline {
                  sys {
                    id
                  }
                }
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                  description
                }
              }
            }
          }
          description
        }
      }
    }
  `;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      Authorization: "Bearer Y9k68HidHsj7hfIfxud23KXx6sjRnOZX8NTlM92rgwc",
    },
    body: JSON.stringify({
      query,
      variables: { urlName },
    }),
  });
  const data = await graphQLClient.request(query);
  return data.aiPostCollection.items["0"];
}

function renderOptions(responselinks: {
  assets: { block: any };
  entries: { block: any; inline: any };
}) {
  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  for (const asset of responselinks.assets.block) {
    assetMap.set(asset.sys.id, asset);
  }
  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and add them to the map
  for (const entry of responselinks.entries.block) {
    entryMap.set(entry.sys.id, entry);
  }

  // loop through the inline linked entries and add them to the map
  for (const entry of responselinks.entries.inline) {
    entryMap.set(entry.sys.id, entry);
  }
  // create an entry block map
  const entryBlockMap = new Map();

  // loop through the assets and add them to the map
  for (const entry of responselinks.entries.block) {
    entryBlockMap.set(entry.sys.id, entry);
  }

  return {
    renderMark: {
      [MARKS.BOLD]: (
        text:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <b>{text}</b>,
      [MARKS.ITALIC]: (
        text:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (
        text:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <u>{text}</u>,
      [MARKS.CODE]: (
        text:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <code>{text}</code>,
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (node: any, children: any) => children,
      [BLOCKS.PARAGRAPH]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => (
        <p className="text-lg">
          {children}
          <br />
          <br />
        </p>
      ),
      [BLOCKS.HEADING_1]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <h2 className="text-4xl mb-8 mt-16 font-semibold">{children}</h2>,
      [BLOCKS.HEADING_2]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <h3 className="text-3xl mb-8 mt-16">{children}</h3>,
      [BLOCKS.HEADING_3]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <h6>{children}</h6>,
      [BLOCKS.EMBEDDED_ENTRY]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <div>{children}</div>,
      [BLOCKS.UL_LIST]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <ul className="list-disc">{children}</ul>,
      [BLOCKS.OL_LIST]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <ol className="list-decimal">{children}</ol>,
      [BLOCKS.LIST_ITEM]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => (
        <div className="w-10/12 ml-5 md:ml-10">
          <li className="list-decimal">{children}</li>
        </div>
      ),
      [BLOCKS.QUOTE]: (
        node: any,
        children:
          | boolean
          | ReactChild
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <blockquote>{children}</blockquote>,
      [BLOCKS.HR]: () => <hr />,
      [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id);
        // render the entries as needed
        if (entry.__typename === "BlogPost") {
          return <a href={`/blog/${entry.slug}`}>{entry.title} </a>;
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
        // find the entry in the entryMap by ID
        const entry = entryBlockMap.get(node.data.target.sys.id);
        // render the entries as needed by looking at the __typename
        // referenced in the GraphQL query
        if (entry.__typename === "CodeBlock") {
          return (
            <pre>
              <code>{entry.code}</code>
            </pre>
          );
        }
        if (entry.__typename === "videoEmbed") {
          return (
            <iframe
              src={entry.embedUrl}
              height="100%"
              width="100%"
              frameBorder="0"
              className="mt-10 mb-16"
              scrolling="no"
              title={entry.title}
              allowFullScreen={true}
            />
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id);
        // render the asset accordingly
        return (
          <img
            src={asset.url}
            alt={asset.description}
            className="mt-10 mb-16"
          />
        );
      },
    },
  };
}

export default function AIBlogPost() {
  const [isLoading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [notFound, setNotFound] = useState(false);
  const [title, setTitle] = useState();
  const [content, setContent] = useState<Document>();
  //const [author, setAuthor] = useState();
  const [links, setLinks] = useState<Options>();
  //const [description, setDescription] = useState();
  //const [tags, setTags] = useState();
  useEffect(() => {
    setLoading(true);
    blogquery()
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .then(function (data) {
        console.log(data);
        if (data) {
          setDate(data.date.substring(0, 10));
          setTitle(data.title);
          document.title = "KTR Studio - " + title;

          setLinks(renderOptions(data.content.links));
          //console.log(renderOptions(data.content.links))
          setContent(data.content.json);
          //setAuthor(data.author);
          //setDescription(data.description);
          //setTags(data.tags);
          //console.log(tags)
          setLoading(false);
          setNotFound(false);
        } else {
          setLoading(false);
          setNotFound(true);
        }
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="screen-h">
          <Header />
          <div className="flex flex-col justify-between">
            <div className="flex items-center justify-center bg-grey-50 pt-64">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    if (notFound) {
      return (
        <>
          <div className="screen-h">
            <Header />
            <div className="flex flex-col justify-between">
              <div className="flex items-center justify-center bg-grey-50 pt-64">
                <div role="status">
                    Blog entry could not be found.
                </div>
              </div>
              <div className="absolute bottom-0 w-full">
                <Footer />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <Header />
            <div className="flex flex-col justify-between">
              <div className="flex items-center justify-center bg-grey-50">
                <div className="container w-full md:max-w-7xl mx-auto px-4">
                  <div className="font-sans">
                    <div className="w-full md: mx-auto md:max-w-3xl mt-10 px-4 md:px-6 text-xl text-gray-800 leading-normal">
                      <h1 className="text-center text-3xl md:text-3xl font-bold tracking-tight text-slate-600 sm:text-4xl mb-10">
                        {title}
                      </h1>

                      {/*

										<p className="text-sm md:text-base font-normal text-gray-600 mb-16">
											Published by {author} on {date}</p>
										*/}

                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.3 } }}
                        exit={{ opacity: 0, transition: { duration: 0.3 } }}
                        className="content"
                      >
                        {documentToReactComponents(content as Document, links)}
                      </motion.div>
                    </div>
                  </div>
                  {/*}
								<div className="text-base md:text-sm text-gray-500 px-4 py-6 mb-6">
									Tags: "asd"
								</div>

								<hr className="border-b-2 border-gray-400 mb-8 mx-4" />

								*/}
                  <CallToAction />

                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
