import { Logo20Scaled10 } from "./Logo";
import { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom";

function getBlog(contentType: string): string {
  if (contentType === "musicPost") {
    return "KTR Music Blog";
  } else if (contentType === "aiPost") {
    return "KTR AI Blog";
  } else if (contentType === "singlePost") {
    return "KTR Documentation";
  } else {
    return contentType;
  }
}

export default function Header() {
  const [navbarOpen, setNavbarOpen] = useState(true);
  const handleToggle = () => {
    setNavbarOpen((prev) => !prev);
  };
  const searchClient = algoliasearch(
    "E7JOV45CJU",
    "ed5d3e924d3c4aba8d8a836035b2a393"
  );
  const Hit = ({ hit }: { hit: any }) => (
    <a href={hit.url}>
      <div className="relative py-2 px-2 ml-2 bg-white w-80 text-lg z-10 hover:bg-gray-100 mb-2 mt-1 rounded-2xl shadow-xl shadow-gray-500">
        <div className="ml-1">
          <div className="text-sm p-1 w-28 rounded-xl  bg-blue text-white text-center">
            {getBlog(hit.contentType)}
          </div>
          <div className=" ml-2 text-xl font-bold my-2">{hit.title}</div>
          <div className="ml-2 text-xs mb-3">{hit.description}</div>
        </div>
      </div>
    </a>
  );

  const SearchBox = ({
    currentRefinement,
    refine,
  }: {
    currentRefinement: any;
    refine: any;
  }) => (
    <div className="flex items-center justify-center px-2">
      <div className="w-80">
        <label className="sr-only">Search</label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            className="block w-full pl-10 pr-3 py-2 border  border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue focus:border-blue sm:text-sm"
            placeholder="Search"
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  );
  const CustomSearchBox = connectSearchBox(SearchBox);
  const Results = connectStateResults(({ searchState }: { searchState: any }) =>
    searchState && searchState.query ? (
      <div className="absolute">
        <Hits hitComponent={Hit} />
      </div>
    ) : //<div>No query</div>
    null
  );

  return (
    <nav className="bg-grey-50 border-gray-200 px-2 sm:px-4 py-2.5 rounded">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
          <Logo20Scaled10 />
          <div className="self-center text-xl font-semibold whitespace-nowrap font-sans">
            Higent.ai
          </div>
        </a>
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          onClick={handleToggle}
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className={navbarOpen ? "w-6 h-6" : "hidden w-6 h-6"}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className={navbarOpen ? "hidden w-6 h-6" : "w-6 h-6"}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={
            navbarOpen
              ? "hidden w-full md:block md:w-auto"
              : "w-full md:block md:w-auto"
          }
          id="mobile-menu"
        >
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <InstantSearch indexName="KTRBlog" searchClient={searchClient}>
                <div className="right-panel">
                  {/* <CustomSearchBox /> */}
                  <Results />
                </div>
              </InstantSearch>
            </li>
            <li>
              <a
                href="/"
                className="block mt-2 pr-4 pl-3 text-gray-700 hover:text-gray-900 rounded md:p-0  md:hover:bg-transparent"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="block mt-2 pr-4 pl-3 text-gray-700 md:border-0 hover:text-gray-900 md:p-0 md:hover:text-gray-900"
              >
                About
              </a>
            </li>
            {/* Reworked and removed unnecessary classes, and commented out section remains commented */}
            <li>
              <a
                href="/contact"
                className="block mt-2 pr-4 pl-3 text-gray-700 md:border-0 hover:text-gray-900 md:p-0"
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="/blog"
                className="block mt-2 pr-4 pl-3 text-gray-700 md:border-0 hover:text-gray-900 md:p-0"
              >
                Blog
              </a>
            </li>
            <li>
              <button
                onClick={() =>
                  (window.location.href = `https://pagewhereuserentershisemail.ktr-solutions.com`)
                }
                className="flex mx-auto py-2 px-4 text-white text-base font-semibold tracking-wider rounded shadow bg-sky-800 hover:bg-sky-900"
              >
                Sign Up
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
