import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import CTASection from '../components/CallToAction';
import Footer from '../components/Footer';
import VillainSection from '../components/VillainSection';
import FeatureSection from '../components/FeatureSection';
import GuideSection from '../components/GuideSection';

const LandingPage: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <FeatureSection />
      <VillainSection/>
      <GuideSection />
      <CTASection />
      <Footer />
    </div>
  );
};

export default LandingPage;
