import { useEffect } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from 'framer-motion';
import { FaLinkedin } from 'react-icons/fa';
import CallToAction from '../components/CallToAction';

const people = [
  {
    name: 'Justin Kater',
    role: 'Co-Founder / CEO',
    imageUrl: '../img/justin.jpg',  // Replace with actual image path
    bio: 'Justin has extensive experience in generative AI, specializing in custom models and cloud solutions. As a former domain architect, he has led numerous AI implementations.',
    linkedinUrl: 'https://www.linkedin.com/in/justin-kater-21059a210/',
    xUrl: 'https://github.com/justin-kater',  // Replace with actual GitHub or other link if needed
  },
  {
    name: 'Colin Kater',
    role: 'Co-Founder / CTO',
    imageUrl: '../img/colin.jpg',  // Replace with actual image path
    bio: 'Colin is an expert in software architecture with experience as a management consultant, using his expertise to optimize business processes and implement scalable AI-driven solutions.',
    linkedinUrl: 'https://www.linkedin.com/in/colin-kater/',
    xUrl: 'https://github.com/colin-kater',  // Replace with actual GitHub or other link if needed
  },
]

function Example() {
  return (
    <div className="py-16 md:py-16">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the team</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          KTR Solutions, founded in 2022, helps small and medium-sized businesses stay competitive through AI-powered solutions.<br/><br/>
          Drawing on 10 years of combined experience in software and AI, our leadership team is committed to delivering AI solutions that create real-world value.
          </p>
        </div>
        <ul role="list" className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
          {people.map((person) => (
            <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
              <img alt="" src={person.name === "Justin Kater" ? require('../img/justin.jpg') : require('../img/colin.jpeg')} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />
              <div className="max-w-xl flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                <ul role="list" className="mt-6 flex gap-x-6">
                  {/* <li>
                    <a href={person.xUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">X</span>
                      <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
                        <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                      </svg>
                    </a>
                  </li> */}
                  <li>
                    <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">LinkedIn</span>
                      <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
                        <path
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


export default function About() {
  useEffect(() => {
    document.title = "KTR Studio - About";
  }, []);

  return (
    <>
      <Header />
      <div className="bg-gray-50 overflow-hidden">
        <Example />

        <CallToAction />
        {/* Footer Section */}
        <div className="lg:w-full">
          <Footer />
        </div>
      </div>
    </>
  );
}
