import React from 'react';

const VillainSection: React.FC = () => {
return (
<div className="overflow-hidden bg-gray-50 py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
            {/* Left Side */}
            <div className="lg:w-1/2">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-base font-semibold leading-7 text-sky-800">The ugliest 4 hours of your work week</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Disconnected Tools Are Slowing Down Your Business
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Your software should work for you, not the other way around. When tools don't communicate,
                        processes break down, time is wasted, and frustration builds.
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row lg:space-x-8 mt-8">
                    <div className="lg:w-1/3">
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                            Lost Time
                        </dt>
                        <dd className="mt-1 text-base leading-7 text-gray-600">
                            Hours lost to manual processes that could be automated.
                        </dd>
                    </div>
                    <div className="lg:w-1/3 mt-8 lg:mt-0">
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                            Loss of Focus
                        </dt>
                        <dd className="mt-1 text-base leading-7 text-gray-600">
                            Switching between disconnected tools breaks concentration and hinders productivity.
                        </dd>
                    </div>
                    <div className="lg:w-1/3 mt-8 lg:mt-0">
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                            Lost Information
                        </dt>
                        <dd className="mt-1 text-base leading-7 text-gray-600">
                            Important data lost in the shuffle between tools, causing delays and errors.
                        </dd>
                    </div>
                </div>
            </div>


            {/* Right Side */}
            <div className="mt-16 mx-auto">
            <img alt="AI-powered automation illustration"
                    src="/img/undraw_void.svg"
                    className="mt-6 h-72" />
            </div>
        </div>
    </div>
</div>
);
};

export default VillainSection;